import React from "react";
import { Link } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";
import { graphql } from "gatsby";
import {
  Section,
  SectionPageTitle,
  SectionDescription,
  BreadCrumb,
} from "../components/section";
import ProductCardList from "../components/TabProductCardList";
import generateHTML from "../utils/generateHTML";
import Content, { HTMLContent } from "../components/Content";
import QuotePopup from "../components/QuotePopup";

class ProductsPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isVisibleQuote: false,
    };
    this.showQuote = this.showQuote.bind(this);
    this.onClosePopup = this.onClosePopup.bind(this);
  }
  showQuote() {
    this.setState({
      isVisibleQuote: true,
    });
    if (typeof window !== `undefined`) {
      document.body.classList.add("modal-open");
    }
  }
  onClosePopup() {
    this.setState({
      isVisibleQuote: false,
    });
    if (typeof window !== `undefined`) {
      document.body.classList.remove("modal-open");
    }
  }
  componentDidMount() {}
  render() {
    const { isVisibleQuote } = this.state;
    const { data } = this.props;
    const pageData = data.contentfulProductListPage;
    const products = data.allContentfulProduct;
    let productsData = [];
    productsData = products.edges;
    const PostContent = HTMLContent || Content;
    return (
      <Layout>
        <SEO
          title={pageData.metaTitle}
          description={pageData.metaDescription}
        />
        <Section pt="150px" pb="35px" bg="#F4F8FC" bdrBottom="#ddd">
          <div className="container">
            <BreadCrumb>
              <Link to="/">Home</Link>/<span>All Products</span>
            </BreadCrumb>
            <SectionPageTitle mt="30px" mb="20px" textAlign="center">
              {pageData.heroTitle}
            </SectionPageTitle>
            <SectionDescription>
              <PostContent
                content={generateHTML(
                  pageData.heroDescription.childMarkdownRemark.html
                )}
              />
            </SectionDescription>
          </div>
        </Section>
        <Section pt="70px" pb="35px">
          <div className="container">
            <ProductCardList
              data={productsData}
              limit="all"
              showQuote={this.showQuote}
            />
          </div>
        </Section>
        <QuotePopup isVisible={isVisibleQuote} onClose={this.onClosePopup} />
      </Layout>
    );
  }
}

export default ProductsPage;

export const pageQuery = graphql`
  query ProductsPageQuery {
    contentfulProductListPage {
      metaTitle
      metaDescription
      heroTitle
      heroDescription {
        childMarkdownRemark {
          html
        }
      }
    }
    allContentfulProduct {
      edges {
        node {
          productName
          url
          popularLabel
          image {
            fluid {
              base64
              aspectRatio
              src
              srcSet
              srcSetWebp
              srcWebp
            }
          }
          width
          height
          length
          totalPrice
          priceDescription
          featuresImage {
            title
            file {
              url
            }
          }
          productRoof {
            roofName
          }
        }
      }
    }
  }
`;
